import { Injectable } from '@angular/core';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { TimeZoneService } from '../index';
import { ITimeZones } from '../../shared/models';
import { GetTimeZones } from './global.actions';
import { GlobalStateModel } from './global.model';
import { Observable, tap } from 'rxjs';

const defaults: GlobalStateModel = {
    timezones: null,
};

@State<GlobalStateModel>({
    name: 'globalState',
    defaults,
})
@Injectable()
export class GlobalState implements NgxsOnInit {
    constructor(private readonly _timeZoneService: TimeZoneService) {}

    @Selector()
    public static timezones(state: GlobalStateModel): ITimeZones {
        return state.timezones;
    }

    @Action(GetTimeZones)
    public getTimeZones(ctx: StateContext<GlobalStateModel>): Observable<ITimeZones> {
        return this._timeZoneService.getTimeZones().pipe(
            tap((timezones: ITimeZones) => {
                ctx.patchState({ timezones });
            })
        );
    }

    public ngxsOnInit(ctx: StateContext<GlobalStateModel>): void {
        ctx.dispatch(new GetTimeZones());
    }
}
