import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
    public transform(phone: string): string {
        if (isNil(phone)) {
            return '';
        }

        const pattern = /^(\d{3})(\d{3})(\d{4})$/;
        const matches = phone.match(pattern);

        if (!matches) {
            return phone;
        }

        return `(${matches[1]}) ${matches[2]} - ${matches[3]}`;
    }
}
