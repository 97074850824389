import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITimeZones } from '../../shared/models';
import { AppSettings } from '../settings';

@Injectable({
    providedIn: 'root',
})
export class TimeZoneService {
    constructor(private _http: HttpClient) {}

    public getTimeZones(): Observable<ITimeZones> {
        return this._http.get<ITimeZones>(`${AppSettings.apiHost}/serialnumber/v2/info/timezones`);
    }
}
