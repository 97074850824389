import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { SnackBarService } from '../../../layout';
import { SNACK_BAR_CONFIG_ERROR } from '../../../layout/pecan/constants';
import { AuthService } from '../../auth/services/auth.service';
import { TokenService } from '../../auth/services/token.service';
import { VERSION } from '../../constants';
import { ResponseCode } from '../../enum';
import { INCLUDE_TOKEN } from '../context';
import { UserResponseCode } from '../enums';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
    constructor(
        private _tokenService: TokenService,
        private _authService: AuthService,
        private _snackBarService: SnackBarService
    ) {}

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(this._constructHeaders(req)).pipe(catchError((error: HttpErrorResponse) => this._handleError(error, req, next)));
    }

    private _handleError(res: HttpErrorResponse, req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        switch (res.status) {
            case UserResponseCode.Unauthorized:
                return this._handleNoAccessError(req, next);
            case UserResponseCode.Forbidden:
                this._handleInternalServerError(res, 'forbid_permission');
                break;
            case ResponseCode.BadRequest:
                this._snackBarService.showDefaultErrorNotification('bad_request');
                break;
            case ResponseCode.BadGateway:
            case ResponseCode.ServiceUnavailable:
            case ResponseCode.NoResponse:
                this._snackBarService.showDefaultErrorNotification('server_error');
                break;
            case ResponseCode.InternalServerError:
                this._handleInternalServerError(res);
                break;
            default:
        }

        return throwError(res);
    }

    private _constructHeaders(req: HttpRequest<unknown>): HttpRequest<unknown> {
        const includeToken = req.context.get(INCLUDE_TOKEN);

        if (!includeToken) {
            return req;
        }

        return req.clone({
            headers: req.headers.append('Authorization', `Bearer ${this._tokenService.token}`).append('Client-Type', `Cloud UI/${VERSION}`),
        });
    }

    private _handleNoAccessError(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return this._authService.getAuthenticationToken().pipe(
            filter(Boolean),
            take(1),
            switchMap(() => next.handle(this._constructHeaders(req))),
            catchError((error: HttpErrorResponse) => {
                this._authService.logout();

                return of(error);
            })
        ) as Observable<HttpEvent<unknown>>;
    }

    private _handleInternalServerError(res: HttpErrorResponse, definedMessage?: string): void {
        const message = definedMessage ?? 'error_in_app';
        const config = {
            ...SNACK_BAR_CONFIG_ERROR,
            data: { message },
        };

        if (!res.headers.get('Content-Type')?.includes('text/html') && !definedMessage?.length) {
            config.data.message = res.error || message;
        }

        this._snackBarService.showDefaultErrorNotification(message);
    }
}
