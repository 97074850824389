export enum RoutesKey {
    licenses = 'licenses',
    companies = 'companies',
    updates = 'updates',
    user = 'user',
    dashboard = 'dashboard',
    reports = 'reports',
    profile = 'profile',
    dealers = 'dealers',
    contacts = 'contacts',
    activities = 'activities',
    home = 'home',
}
